// authentication types
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT = 'LOGOUT'
export const USER_LOADING= 'USER_LOADING'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const RESET_ERROR = 'RESET_ERROR'
export const CONFIRM_RESET_SUCCESS = 'CONFIRM_RESET_SUCCESS'
export const CONFIRM_RESET_FAILURE = 'CONFIRM_RESET_FAILURE'


// message types
export const SET_MESSAGE = 'SET_MESSAGE'
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE'

export const DEPOSIT_LOADING = 'DEPOSIT_LOADING'
export const DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS'
export const DEPOSIT_FAILURE = 'DEPOSIT_FAILURE'
export const WITHDRAW_SUCCESS = 'WITHDRAW_SUCCESS'
export const WITHDRAW_ERROR = 'WITHDRAW_ERROR'

// user verification
export const VERIFICATION_LOADING = 'VERIFICATION_LOADING'
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS'
export const VERIFICATION_FAILURE = 'VERIFICATION_FAILURE'

// save details
export const SAVE_DETAILS_SUCCESS = 'SAVE_DETAILS_SUCCESS'
export const SAVE_DETAILS_ERROR = 'SAVE_DETAILS_ERROR'

// get a user deposits
export const DEPOSITS_LOADING = 'DEPOSITS_LOADING'
export const DEPOSITS_SUCCESS = 'DEPOSITS_SUCCESS'
export const DEPOSITS_ERROR = 'DEPOSITS_ERROR'

// education payment
export const COURSE_DEP_LOADING = 'COURSE_DEP_LOADING'
export const COURSE_DEP_SUCCESS = 'COURSE_DEP_SUCCESS'
export const COURSE_DEP_FAILURE = 'COURSE_DEP_FAILURE'


// ADMIN ACTIONS

// get all users
export const ALL_USERS_LOADING = 'ALL_USERS_LOADING'
export const ALL_USERS_LOADED = 'ALL_USERS_LOADED'
export const ALL_USERS_ERROR = 'ALL_USERS_ERROR'

// Get single User
export const GET_USER_LOADING = 'GET_USER_LOADING'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_ERROR = 'GET_USER_ERROR'

// get investment plans
export const GET_PLANS_LOADING = 'GET_PLANS_LOADING'
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS'
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE'

// get one investment plan 
export const GET_PLAN_LOADING = 'GET_PLAN_LOADING'
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS'
export const GET_PLAN_FAILURE = 'GET_PLAN_FAILURE'

// create investment plan
export const CREATE_PLAN_LOADING = 'CREATE_PLAN_LOADING'
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS'
export const CREATE_PLAN_FAILURE = 'CREATE_PLAN_FAILURE'

// update investment plan
export const UPDATE_PLAN_LOADING = 'UPDATE_PLAN_LOADING'
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS'
export const UPDATE_PLAN_FAILURE = 'UPDATE_PLAN_FAILURE'

// delete investment plan
export const DELETE_PLAN_LOADING = 'DELETE_PLAN_LOADING'
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS'
export const DELETE_PLAN_FAILURE = 'DELETE_PLAN_FAILURE'

// User deposit types
export const ALL_DEPOSITS_LOADING = 'ALL_DEPOSITS_LOADING'
export const ALL_DEPOSITS_SUCCESS = 'ALL_DEPOSITS_SUCCESS'
export const ALL_DEPOSITS_FAILURE = 'ALL_DEPOSITS_FAILURE'

// A user deposits 
export const USER_DEPS_LOADING = 'USER_DEPS_LOADING'
export const USER_DEPS_SUCCESS = 'USER_DEPS_SUCCESS'
export const USER_DEPS_FAILURE = 'USER_DEPS_FAILURE'

// admin authorize deposit
export const CONFIRM_DEP_LOADING = 'CONFIRM_DEPOSIT_LOADING'
export const CONFIRM_DEP_SUCCESS = 'CONFIRM_DEPOSIT_SUCCESS'
export const CONFIRM_DEP_FAILURE = 'CONFIRM_DEPOSIT_FAILURE'

// admin authorize verification
export const CONFIRM_VER_LOADING = 'CONFIRM_VERIFICATION_LOADING'
export const CONFIRM_VER_SUCCESS = 'CONFIRM_VERIFICATION_SUCCESS'
export const CONFIRM_VER_FAILURE = 'CONFIRM_VERIFICATION_FAILURE'

// admin authorize verification directly
export const AUTH_VER_LOADING = 'AUTH_VER_LOADING'
export const AUTH_VER_SUCCESS = 'AUTH_VER_SUCCESS'
export const AUTH_VER_ERROR = 'AUTH_VER_ERROR'

// All verification requests
export const ALL_REQUESTS_LOADING = 'ALL_REQUESTS_LOADING'
export const ALL_REQUESTS_SUCCESS = 'ALL_REQUESTS_SUCCESS'
export const ALL_REQUESTS_FAILURE = 'ALL_REQUESTS_FAILURE'

// Get One verification request
export const VER_REQUEST_LOADING = 'VER_REQUEST_LOADING'
export const VER_REQUEST_SUCCESS = 'VER_REQUEST_SUCCESS'
export const VER_REQUEST_FAILURE = 'VER_REQUEST_FAILURE'